import './App.css';
import ToshibaAPI from './comp/interface'

function App() {
  return (
    <>
      <ToshibaAPI />
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react';

const App = () => {
  const username = "Messa69";
  const password = "oFKkCVQD-9*F";
  const baseUrl = "https://mobileapi.toshibahomeaccontrols.com";
  const loginUrl = "/api/Consumer/Login";
  const deviceUrl = "/api/AC/GetRegisteredACByUniqueId";
  const mappingUrl = "/api/AC/GetConsumerACMapping";
  const statusUrl = "/api/AC/GetCurrentACState";
  const settingsUrl = "/api/AC/GetConsumerProgramSettings";

  const [accessToken, setAccessToken] = useState(null);
  const [consumerId, setConsumerId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [loginResult, setLoginResult] = useState({});
  const [mappingResult, setMappingResult] = useState({});
  const [statusResult, setStatusResult] = useState({});
  const [settingsResult, setSettingsResult] = useState({});
  
  const query = async (url, post = null, token = null) => {
    try {
      const response = await fetch(url, {
        method: post ? 'POST' : 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token && { 'Authorization': 'Bearer ' + token })
        },
        ...(post && { body: JSON.stringify(post) })
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error in query: ", url, error);
      return false;
    }
  };

  useEffect(() => {
    const performLogin = async () => {
      const fields = { Username: username, Password: password };
      const fieldsString = JSON.stringify(fields);
      const result = await query(baseUrl + loginUrl, fieldsString);

      if (result && result.ResObj) {
        setAccessToken(result.ResObj.access_token);
        setConsumerId(result.ResObj.consumerId);
        setLoginResult(result);
      }
    };

    performLogin();
  }, []);

  useEffect(() => {
    const performMapping = async () => {
      if (consumerId && accessToken) {
        const fields = { consumerId };
        const result = await query(`${baseUrl}${mappingUrl}?${new URLSearchParams(fields)}`, null, accessToken);

        if (result && result.ResObj && result.ResObj[0] && result.ResObj[0].ACList) {
          setDeviceId(result.ResObj[0].ACList[0].Id);
          setMappingResult(result);
        }
      }
    };

    performMapping();
  }, [consumerId, accessToken]);

  useEffect(() => {
    const fetchDeviceStatus = async () => {
      if (deviceId && accessToken) {
        const fields = { ACId: deviceId };
        const result = await query(`${baseUrl}${statusUrl}?${new URLSearchParams(fields)}`, null, accessToken);
        setStatusResult(result);
      }
    };

    fetchDeviceStatus();
  }, [deviceId, accessToken]);

  useEffect(() => {
    const fetchSettings = async () => {
      if (consumerId && accessToken) {
        const fields = { consumerId };
        const result = await query(`${baseUrl}${settingsUrl}?${new URLSearchParams(fields)}`, null, accessToken);
        setSettingsResult(result);
      }
    };

    fetchSettings();
  }, [consumerId, accessToken]);

  return (
    <div>
      <pre>Login Result: {JSON.stringify(loginResult, null, 2)}</pre>
      <pre>Mapping Result: {JSON.stringify(mappingResult, null, 2)}</pre>
      <pre>Device Status Result: {JSON.stringify(statusResult, null, 2)}</pre>
      <pre>Settings Result: {JSON.stringify(settingsResult, null, 2)}</pre>
    </div>
  );
};

export default App;